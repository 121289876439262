import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";

type OnboardingState = boolean;
const initialState: OnboardingState = false;
export const onboardedName = "onboarded";

const onboardingSlice = createSlice({
  name: onboardedName,
  initialState,
  reducers: {
    onboarded: (state) => true,
  },
});

export const { onboarded } = onboardingSlice.actions;

export const selectOnboarded: (s: RootState) => OnboardingState = (state) =>
  state.onboarding;

export default onboardingSlice.reducer;
