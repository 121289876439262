import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";

export const layoutName = "layout";

type LayoutState = {
  headerHeight: number;
};
const initialState: LayoutState = {
  headerHeight: 0,
};

const layoutSlice = createSlice({
  name: layoutName,
  initialState,
  reducers: {
    setHeaderHeight: (state, action) => {
      state.headerHeight = action.payload;
    },
  },
});

export const { setHeaderHeight } = layoutSlice.actions;

export const selectHeaderHeight: (s: RootState) => number = (state) =>
  state.layout.headerHeight;

export default layoutSlice.reducer;
