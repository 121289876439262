import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import { Constituent } from "../types/Constituent";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import * as config from "../config/config.json";
import { MUIDataTableColumn, MUIDataTableColumnDef } from "mui-datatables";
import debugLog from "../config/debugLog";
import { GetParams, Pagination } from "../types/Pagination";

const {
  REACT_APP_CONSTITUENT_API_URL,
  REACT_APP_STORAGE_API_URL,
} = process.env;
export const constituentApiUrl: string = REACT_APP_CONSTITUENT_API_URL!;
const storageApiUrl: string = REACT_APP_STORAGE_API_URL!;

interface GetConstituentsResponse {
  pagination: Pagination;
  constituents: Constituent[];
  meta: {
    additional_columns: MUIDataTableColumnDef[];
  };
}

export interface AddConstituentResponse {
  message: string;
  constituent: Constituent;
}

export interface UpdateConstituentResponse {
  message: string;
  constituent: Constituent;
}

export interface SignedPostUrlResponse {
  url: string;
  fields: SignedPostUrlFields;
}

export interface SignedPostUrlFields {
  AWSAccessKeyId: string;
  acl: string;
  key: string;
  policy: string;
  signature: string;
  x_amz_security_token: string;
}

type ConstituentsState = {
  items: GetConstituentsResponse["constituents"];
  pagination: GetConstituentsResponse["pagination"];
  additional_columns: GetConstituentsResponse["meta"]["additional_columns"];
  params: GetParams;
  getStatus: "idle" | "loading" | "loaded" | "error" | "changed";
  addStatus: "idle" | "adding" | "added" | "error";
  uploadStatus: "idle" | "uploading" | "uploaded" | "error";
  updateStatus: "idle" | "updating" | "updated" | "error";
  filteredBySearch: boolean;
  error: string | undefined | null;
  success: string | undefined | null;
};

const initialState: ConstituentsState = {
  items: [],
  pagination: {
    current_page: 1,
    has_next: false,
    has_previous: false,
    next_page_number: null,
    number_of_pages: 1,
    previous_page_number: null,
    total_items: 0,
  },
  additional_columns: [],
  params: {
    page_size: config.constituents.defaultRowsPerPage,
    page: 1,
    q: null,
    sort_by: "email",
    sort_order: "asc",
  },
  getStatus: "idle",
  addStatus: "idle",
  uploadStatus: "idle",
  updateStatus: "idle",
  filteredBySearch: false,
  error: null,
  success: null,
};

export const constituentsName = "constituents";

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getConstituents = createAsyncThunk<
  // Return type of the payload creator
  GetConstituentsResponse,
  // First argument to the payload creator
  void,
  { state: RootState }
>("constituents/get", async (_, { getState, signal }) => {
  const timeout = config.apiCallTimeout;
  const { constituents } = getState();
  const params = constituents.params;
  const source = axios.CancelToken.source();
  const retries = 5;
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  debugLog("GET CONSTITUENTS");
  // If there is no search param, we retry on an empty response, to make sure there's nothing we are waiting for
  for (let i = 0; i < retries; i++) {
    const response = await axios.get(`${constituentApiUrl}/`, {
      params: params,
      cancelToken: source.token,
      timeout: timeout,
    });
    const data = response.data as GetConstituentsResponse;
    if (data.constituents.length > 0 || params.q || i === retries - 1) {
      return data;
    }
    await sleep(i * 1000);
  }
  return {} as GetConstituentsResponse;
});

export const addConstituent = createAsyncThunk<
  AddConstituentResponse,
  Constituent,
  { state: RootState }
>("constituents/add", async (item, { signal }) => {
  const timeout = config.apiCallTimeout;
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  debugLog("START PUT REQUEST");
  return new Promise((resolve, reject) => {
    axios
      .put(`${constituentApiUrl}/`, item, {
        cancelToken: source.token,
        timeout: timeout,
      })
      .then((response) => {
        resolve(response.data as AddConstituentResponse);
      })
      .catch((error) => {
        reject(error);
      });
  });
});

export const updateConstituent = createAsyncThunk<
  UpdateConstituentResponse,
  Constituent,
  { state: RootState }
>("constituent/update", async (item, { signal }) => {
  const constituentUpdateApiUrl: string = `${constituentApiUrl}/${item.ref}`;
  delete item.ref; // removing reference email from item as we only need it to set the ApiUrl and we don't want to store it
  const timeout = config.apiCallTimeout;
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  debugLog("START POST REQUEST", item);
  return new Promise((resolve, reject) => {
    axios
      .post(constituentUpdateApiUrl, item, {
        cancelToken: source.token,
        timeout: timeout,
      })
      .then((response) => {
        debugLog(response);
        resolve(response.data as AddConstituentResponse);
      })
      .catch((error) => {
        debugLog(error);
        reject(error);
      });
  });
});

// Get one time use URL to upload direct to s3
const getSignedPostUrl = (filename: string): Promise<SignedPostUrlResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${storageApiUrl}/`, {
        filename: filename,
        namespace: "constituents",
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Upload file stright to AWS S3
const uploadToS3 = (
  data: SignedPostUrlResponse,
  file: File,
  filename: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    for (const [k, v] of Object.entries(data.fields)) {
      formData.append(k, v);
    }
    // Always add the file last, or AWS will complain
    formData.append("file", file, filename);

    axios
      .post(data.url, formData, {
        transformRequest: (data, headers) => {
          if (headers) {
            delete headers["Authorization"];
            headers["Content-Type"] = "multipart/form-data";
          }
          return data;
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadCSV = createAsyncThunk<string, File, { state: RootState }>(
  "constituents/uploadCSV",
  async (file, { getState, signal }) => {
    return new Promise((resolve, reject) => {
      const source = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        source.cancel();
      });

      debugLog("START UPLOAD CSV REQUEST");
      const filename = `${uuidv4()}.csv`;

      getSignedPostUrl(filename)
        .then((response) => {
          uploadToS3(response, file, filename)
            .then(() => resolve("ok"))
            .catch((err) => {
              debugLog(err);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const deleteConstituent = createAsyncThunk<
  {},
  Constituent,
  { state: RootState }
>("constituents/delete", async (item, { signal }) => {
  // const timeout = config.apiCallTimeout;
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  debugLog("START DELETE REQUEST", item);
  return new Promise((resolve, reject) => {
    //   axios
    //     .put(`${constituentApiUrl}/`, item, {
    //       cancelToken: source.token,
    //       timeout: timeout,
    //     })
    //     .then((response) => {
    //       resolve(response.data as AddConstituentResponse);
    //     })
    //     .catch((error) => {
    //       reject(error);
    //     });
  });
});

const constituentsSlice = createSlice({
  name: constituentsName,
  initialState,
  reducers: {
    resetGetStatus: (state) => {
      state.getStatus = "idle";
      state.params = initialState.params;
      state.filteredBySearch = initialState.filteredBySearch;
    },
    resetAddStatus: (state) => {
      state.addStatus = "idle";
    },
    resetUploadStatus: (state) => {
      state.uploadStatus = "idle";
    },
    resetUpdateStatus: (state) => {
      state.updateStatus = "idle";
    },
    changeParams: (state, action) => {
      debugLog(action);
      state.params = action.payload;
      state.getStatus = "changed";
    },
    filteredBySearch: (state, action) => {
      state.filteredBySearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConstituents.pending, (state) => {
      state.getStatus = "loading";
    });
    builder.addCase(getConstituents.fulfilled, (state, action) => {
      state.getStatus = "loaded";
      state.items = action.payload.constituents;
      state.pagination = action.payload.pagination;
      state.additional_columns = action.payload.meta.additional_columns;
      debugLog("GET CONSTITUENTS SUCCESS", action.payload);
    });
    builder.addCase(getConstituents.rejected, (state, action) => {
      state.getStatus = "error";
      state.error = action.error.message;
      debugLog("GET CONSTITUENTS ERROR", action.error);
    });

    builder.addCase(addConstituent.pending, (state) => {
      state.addStatus = "adding";
    });
    builder.addCase(addConstituent.fulfilled, (state, action) => {
      state.addStatus = "added";
      state.success = action.payload.message || "Constituent added";
      state.getStatus = "changed";
      debugLog("ADDED", action.payload);
    });
    builder.addCase(addConstituent.rejected, (state, action) => {
      state.addStatus = "error";
      state.error = action.error.message;
      debugLog("ADD ERROR", action.error);
    });
    // updateConstituent reduders
    builder.addCase(updateConstituent.pending, (state) => {
      state.updateStatus = "updating";
    });
    builder.addCase(updateConstituent.rejected, (state, action) => {
      state.updateStatus = "error";
      state.error = action.error.message;
      debugLog("UPDATE ERROR", action.error);
    });
    builder.addCase(updateConstituent.fulfilled, (state, action) => {
      state.updateStatus = "updated";
      state.success =
        action.payload.message ||
        `Changes to ${action.payload.constituent.first_name} ${action.payload.constituent.last_name} have been saved, the changes will appear shortly.`;
      state.getStatus = "changed";
      debugLog("UPDATED", action.payload);
    });
    builder.addCase(uploadCSV.pending, (state) => {
      state.uploadStatus = "uploading";
    });
    builder.addCase(uploadCSV.fulfilled, (state) => {
      state.success = "CSV successfully uploaded";
      state.uploadStatus = "uploaded";
    });
    builder.addCase(uploadCSV.rejected, (state, action) => {
      state.error = action.error.message;
      state.uploadStatus = "error";
      debugLog("BULK UPLOAD ERROR", action.error);
    });
  },
});

export const {
  changeParams,
  resetGetStatus,
  resetAddStatus,
  resetUploadStatus,
  resetUpdateStatus,
  filteredBySearch,
} = constituentsSlice.actions;

export const selectConstituents: (s: RootState) => Constituent[] = (state) =>
  state.constituents.items;

export const selectParams: (s: RootState) => GetParams = (state) =>
  state.constituents.params;

export const selectAdditionalColumns: (
  s: RootState
) => MUIDataTableColumnDef[] = (state) => {
  // Filter out the campaigns column name
  let columns = state.constituents.additional_columns.filter((col) => {
    let x: MUIDataTableColumn = col as MUIDataTableColumn;
    return x.name !== "campaigns";
  });
  return columns;
};

export const selectConstituentsLoading: (s: RootState) => boolean = (state) =>
  state.constituents.getStatus === "loading";

export const selectConstituentUpdating: (s: RootState) => boolean = (state) =>
  state.constituents.updateStatus === "updating";

export default constituentsSlice.reducer;
