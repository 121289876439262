import axios from "axios";
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./state/store";
import "./index.css";
import "./config/i18n";
import { CssBaseline } from "@material-ui/core";
import { Loader } from "@valtira4firstname/ui";
import applyAppTokenRefreshInterceptor from "./interceptors/refreshToken";
import applyAddTokenToRequest from "./interceptors/addTokenToRequest";
import { lazyRetry } from "./utils/lazy-retry";
// Global axios configuration
applyAppTokenRefreshInterceptor(axios);
applyAddTokenToRequest(axios);

const App = lazy(() => lazyRetry(() => import("./App")));
ReactDOM.render(
  <Suspense fallback={<Loader type="orbit" fillContainer anonymous />}>
    <Provider store={store}>
      <CssBaseline />
      <App />
    </Provider>
  </Suspense>,
  document.getElementById("root")
);

// expose store when run in Cypress
if ((window as any).Cypress && process.env.NODE_ENV !== "production") {
  (window as any).store = store;
}
