import jwtManager from "../auth/session";

const applyAddTokenToRequest = (axiosClient, customOptions = {}) => {
  const interceptor = (config) => {
    const token = jwtManager.getJwt();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  };

  axiosClient.interceptors.request.use(interceptor, undefined);
};

export default applyAddTokenToRequest;
