import { Action, combineReducers } from "redux";
import onboardingReducer from "./onboardingSlice";
import constituentsReducer from "./constituentsSlice";
import campaignsReducer from "./campaignsSlice";
import blueprintsReducer from "./blueprintsSlice";
import messageAlertReducer from "./messageAlertSlice";
import templatesReducer from "./templateSlice";
import usersReducer from "./usersSlice";
import contentLibraryReducer from "./contentLibrarySlice";
import layoutReducer from "./layoutSlice";
import consentReducer from "./consentSlice";

const appReducer = combineReducers({
  layout: layoutReducer,
  consent: consentReducer,
  onboarding: onboardingReducer,
  constituents: constituentsReducer,
  campaigns: campaignsReducer,
  blueprints: blueprintsReducer,
  templates: templatesReducer,
  users: usersReducer,
  messageAlert: messageAlertReducer,
  contentLibrary: contentLibraryReducer,
});

export const rootReducer = (state: any, action: Action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action); // Set Redux to initial state
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
