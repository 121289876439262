import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";

type MessageAlertState = boolean;
const initialState: MessageAlertState = false;
export const messageAlertName = "messageAlertDismissed";

const messageAlertSlice = createSlice({
  name: messageAlertName,
  initialState,
  reducers: {
    dismissed: (state) => true,
  },
});

export const { dismissed } = messageAlertSlice.actions;

export const selectMessageAlert: (s: RootState) => MessageAlertState = (
  state
) => state.messageAlert;

export default messageAlertSlice.reducer;
