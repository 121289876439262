import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import { Consent } from "src/types/Consent";

export const consentName = "consent";

const initialState: Consent = {
  consentId: "",
  lastConsent: "",
  revision: -1,
  acceptType: "",
  // we add this here to force the consent for GA
  // there's no way currently to set this from client page
  acceptedCategories: ["analytics"],
  rejectedCategories: [],
};

const consentSlice = createSlice({
  name: consentName,
  initialState,
  reducers: {
    setConsent: (state, action) => action.payload,
  },
});

export const { setConsent } = consentSlice.actions;

export const selectConsent: (s: RootState) => Consent = (state) =>
  state.consent;

export default consentSlice.reducer;
