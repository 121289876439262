import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import * as config from "../config/config.json";
import axios from "axios";
import debugLog from "../config/debugLog";
import { GetParams, Pagination } from "../types/Pagination";
import { Template } from "../types/Template";

const { REACT_APP_TEMPLATE_API_URL } = process.env;
const templateApiUrl: string = REACT_APP_TEMPLATE_API_URL!;

interface GetTemplatesResponse {
  pagination: Pagination;
  templates: Template[];
}

type TemplateState = {
  items: Template[];
  pagination: Pagination;
  getStatus: "idle" | "loading" | "loaded" | "error" | "changed";
  error: string | undefined | null;
  success: string | undefined | null;
  params: GetParams;
};

const initialState: TemplateState = {
  items: [],
  pagination: {
    current_page: 1,
    has_next: false,
    has_previous: false,
    next_page_number: null,
    number_of_pages: 1,
    previous_page_number: null,
    total_items: 0,
  },
  params: {
    page_size: 100,
    page: 1,
    q: null,
  },
  getStatus: "idle",
  error: null,
  success: null,
};

export const getTemplates = createAsyncThunk<
  // Return type of the payload creator
  GetTemplatesResponse,
  // First argument to the payload creator
  void,
  { state: RootState }
>("templates/get", async (_, { getState, signal }) => {
  const timeout = config.apiCallTimeout;
  const { templates } = getState();
  const params = templates.params;
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  debugLog("GET TEMPLATES");
  const response = await axios.get(`${templateApiUrl}/`, {
    params: params,
    cancelToken: source.token,
    timeout: timeout,
  });
  return response.data as GetTemplatesResponse;
});

const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    resetGetStatus: (state) => {
      state.getStatus = "idle";
      state.params = initialState.params;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTemplates.pending, (state) => {
      state.getStatus = "loading";
    });
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      state.getStatus = "loaded";
      state.items = action.payload.templates;
      state.pagination = action.payload.pagination;
      debugLog("GET TEMPLATES SUCCESS", action.payload);
    });
    builder.addCase(getTemplates.rejected, (state, action) => {
      state.getStatus = "error";
      state.error = action.error.message;
      debugLog("GET TEMPLATES ERROR", action.error);
    });
  },
});

export const { resetGetStatus } = templatesSlice.actions;

export const selectTemplates: (s: RootState) => Template[] = (state) =>
  state.templates.items;
export const selectTemplatesLoading: (s: RootState) => boolean = (state) =>
  state.templates.getStatus === "loading";

export default templatesSlice.reducer;
