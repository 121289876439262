import { Campaign as CampaignType } from "../types/Campaign";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import * as config from "../config/config.json";
import axios from "axios";
import debugLog from "../config/debugLog";
import { GetParams, Pagination } from "../types/Pagination";

const { REACT_APP_CAMPAIGN_API_URL } = process.env;
export const campaignApiUrl: string = REACT_APP_CAMPAIGN_API_URL!;
export const campaignsName = "campaigns";

export interface AddCampaignResponse {
  message: string;
  campaign: CampaignType;
}

export const getCampaigns = createAsyncThunk<
  GetCampaignsResponse,
  void,
  { state: RootState }
>("campaigns/get", async (_, { getState, signal }) => {
  const timeout = config.apiCallTimeout;
  const { campaigns } = getState();
  const params = campaigns.params;
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  debugLog("GET CAMPAIGNS");
  const response = await axios.get(`${campaignApiUrl}/`, {
    params: params,
    cancelToken: source.token,
    timeout: timeout,
  });
  return response.data as GetCampaignsResponse;
});

export const addCampaign = createAsyncThunk<
  AddCampaignResponse,
  CampaignType,
  { state: RootState }
>("campaigns/add", async (item, { signal }) => {
  const timeout = config.apiCallTimeout;
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  return new Promise((resolve, reject) => {
    axios
      .put(`${campaignApiUrl}/`, item, {
        cancelToken: source.token,
        timeout: timeout,
      })
      .then((response) => {
        debugLog(response);
        resolve(response.data as AddCampaignResponse);
      })
      .catch((error) => {
        debugLog(error);
        reject(error);
      });
  });
});

export const updateCampaign = createAsyncThunk<
  AddCampaignResponse,
  CampaignType,
  { state: RootState }
>("campaigns/update", async (item, { signal }) => {
  const campaign = {
    name: item.name,
    is_archived: item.is_archived,
  };
  const campaignUpdateApiUrl: string = `${campaignApiUrl}/${item.uuid}`;
  const timeout = config.apiCallTimeout;
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  return new Promise((resolve, reject) => {
    axios
      .post(campaignUpdateApiUrl, campaign, {
        cancelToken: source.token,
        timeout: timeout,
      })
      .then((response) => {
        debugLog(response);
        resolve(response.data as AddCampaignResponse);
      })
      .catch((error) => {
        debugLog(error);
        reject(error);
      });
  });
});

export interface GetCampaignsResponse {
  pagination: Pagination;
  campaigns: CampaignType[];
}

type CampaignsState = {
  items: GetCampaignsResponse["campaigns"];
  pagination: GetCampaignsResponse["pagination"];
  params: GetParams;
  getStatus: "idle" | "loading" | "loaded" | "error" | "changed";
  addStatus: "idle" | "adding" | "added" | "error";
  updateStatus: "idle" | "updating" | "updated" | "error";
  filteredBySearch: boolean;
  error?: string | null;
  success?: string | null;
  addedId?: string | null;
};

const initialState: CampaignsState = {
  items: [],
  pagination: {
    current_page: 1,
    has_next: false,
    has_previous: false,
    next_page_number: null,
    number_of_pages: 1,
    previous_page_number: null,
    total_items: 0,
  },
  params: {
    page_size: config.campaigns.defaultRowsPerPage,
    page: 1,
    q: null,
  },
  getStatus: "idle",
  addStatus: "idle",
  updateStatus: "idle",
  filteredBySearch: false,
  error: null,
  success: null,
  addedId: null,
};

const campaignsSlice = createSlice({
  name: campaignsName,
  initialState,
  reducers: {
    resetGetStatus: (state) => {
      state.getStatus = "idle";
      state.params = initialState.params;
      state.filteredBySearch = initialState.filteredBySearch;
    },
    resetAddStatus: (state) => {
      state.addStatus = "idle";
      state.addedId = null;
    },
    resetUpdateStatus: (state) => {
      state.updateStatus = "idle";
    },
    changeParams: (state, action) => {
      debugLog(action);
      state.params = action.payload;
      state.getStatus = "changed";
    },
    filteredBySearch: (state, action) => {
      state.filteredBySearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getCampaigns reducers
    builder.addCase(getCampaigns.pending, (state) => {
      state.getStatus = "loading";
    });
    builder.addCase(getCampaigns.fulfilled, (state, action) => {
      state.getStatus = "loaded";
      state.items = action.payload.campaigns;
      state.pagination = action.payload.pagination;
      debugLog("GET CAMPAIGNS SUCCESS", action.payload);
    });
    builder.addCase(getCampaigns.rejected, (state, action) => {
      state.getStatus = "error";
      state.error = action.error.message;
      debugLog("GET CAMPAIGNS ERROR", action.error);
    });
    // addCampaign reducers
    builder.addCase(addCampaign.pending, (state) => {
      state.addStatus = "adding";
    });
    builder.addCase(addCampaign.fulfilled, (state, action) => {
      state.addStatus = "added";
      state.success = action.payload.message || "Campaign added";
      state.addedId = action.payload.campaign.uuid;
      state.getStatus = "changed";
      debugLog("ADDED", action.payload);
    });
    builder.addCase(addCampaign.rejected, (state, action) => {
      state.addStatus = "error";
      state.error = action.error.message;
      debugLog("ADD ERROR", action.error);
    });
    // updateCampaign reduders
    builder.addCase(updateCampaign.pending, (state) => {
      state.updateStatus = "updating";
    });
    builder.addCase(updateCampaign.rejected, (state, action) => {
      state.updateStatus = "error";
      state.error = action.error.message;
      debugLog("UPDATE ERROR", action.error);
    });

    builder.addCase(updateCampaign.fulfilled, (state, action) => {
      state.updateStatus = "updated";
      state.success = action.payload.message || "Campaign updated";
      debugLog("UPDATED", action.payload);
      const updatedCampaign: CampaignType = action.payload.campaign;
      const existingCampaign = state.items.find(
        (campaign) => campaign.uuid === updatedCampaign.uuid
      );
      if (existingCampaign) {
        existingCampaign.name = updatedCampaign.name;
        existingCampaign.is_archived = updatedCampaign.is_archived;
      }
    });
  },
});

export const {
  changeParams,
  resetGetStatus,
  resetAddStatus,
  resetUpdateStatus,
  filteredBySearch,
} = campaignsSlice.actions;

export const selectCampaigns: (s: RootState) => CampaignType[] = (state) =>
  state.campaigns.items;

export const selectParams: (s: RootState) => GetParams = (state) =>
  state.campaigns.params;

export const selectCampaignsLoading: (s: RootState) => boolean = (state) =>
  state.campaigns.getStatus === "loading";

export const selectCampaignUpdating: (s: RootState) => boolean = (state) =>
  state.campaigns.updateStatus === "updating";

export default campaignsSlice.reducer;
