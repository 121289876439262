import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import { Blueprint, BlueprintEdit } from "../types/Blueprint";
import * as config from "../config/config.json";
import debugLog from "../config/debugLog";
import axios from "axios";
import { GetParams, Pagination } from "../types/Pagination";

const { REACT_APP_BLUEPRINT_API_URL } = process.env;
const blueprintApiUrl: string = REACT_APP_BLUEPRINT_API_URL!;

interface GetBlueprintsResponse {
  pagination: Pagination;
  blueprints: Blueprint[];
}

export interface AddBlueprintResponse {
  message: string;
  blueprint: Blueprint;
}

interface UpdateBlueprintresponse {
  blueprint: Blueprint;
}

export enum GetStatus {
  IDLE = "idle",
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error",
  CHANGED = "changed",
}

export enum AddStatus {
  IDLE = "idle",
  ADDING = "adding",
  ADDED = "added",
  ERROR = "error",
}

export enum UpdateStatus {
  IDLE = "idle",
  UPDATING = "updating",
  UPDATED = "updated",
  ERROR = "error",
}

type BlueprintsState = {
  items: GetBlueprintsResponse["blueprints"];
  pagination: GetBlueprintsResponse["pagination"];
  params: GetParams;
  getStatus: GetStatus;
  addStatus: AddStatus;
  updateStatus: UpdateStatus;
  filteredBySearch: boolean;
  error?: string | null;
  success?: string | null;
  addedId?: string | null;
};

const initialState: BlueprintsState = {
  items: [],
  pagination: {
    current_page: 1,
    has_next: false,
    has_previous: false,
    next_page_number: null,
    number_of_pages: 1,
    previous_page_number: null,
    total_items: 0,
  },
  params: {
    page_size: config.blueprints.defaultRowsPerPage,
    page: 1,
    q: null,
  },
  filteredBySearch: false,
  getStatus: GetStatus.IDLE,
  addStatus: AddStatus.IDLE,
  updateStatus: UpdateStatus.IDLE,
  error: null,
  success: null,
  addedId: null,
};

export const blueprintsName = "blueprints";

export const getBlueprints = createAsyncThunk<
  // Return type of the payload creator
  GetBlueprintsResponse,
  // First argument to the payload creator
  void,
  { state: RootState }
>("blueprints/get", async (_, { getState, signal }) => {
  const timeout = config.apiCallTimeout;
  const { blueprints } = getState();
  const params = blueprints.params;
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  debugLog("GET BLUEPRINTS");
  const response = await axios.get(`${blueprintApiUrl}/`, {
    params: params,
    cancelToken: source.token,
    timeout: timeout,
  });
  return response.data as GetBlueprintsResponse;
});

export const updateBlueprint = createAsyncThunk<
  UpdateBlueprintresponse,
  BlueprintEdit,
  { state: RootState }
>("blueprints/update", async (item, { signal }) => {
  const blueprint = item as Blueprint;
  const blueprintUpdateApiUrl: string = `${blueprintApiUrl}/${blueprint.uuid}`;
  // We don't want to send the UUID in the body as it's in the URL
  try {
    delete blueprint.uuid;
  } catch {}
  const timeout = config.apiCallTimeout;
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  return new Promise((resolve, reject) => {
    axios
      .post(blueprintUpdateApiUrl, blueprint, {
        cancelToken: source.token,
        timeout: timeout,
      })
      .then((response) => {
        debugLog(response);
        resolve(response.data as UpdateBlueprintresponse);
      })
      .catch((error) => {
        debugLog(error);
        reject(error);
      });
  });
});

export const addBlueprint = createAsyncThunk<
  AddBlueprintResponse,
  Blueprint,
  { state: RootState }
>("blueprints/add", async (item, { signal }) => {
  const timeout = config.apiCallTimeout;
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  debugLog("START PUT REQUEST");
  return new Promise((resolve, reject) => {
    axios
      .put(`${blueprintApiUrl}/`, item, {
        cancelToken: source.token,
        timeout: timeout,
      })
      .then((response) => {
        resolve(response.data as AddBlueprintResponse);
      })
      .catch((error) => {
        reject(error);
      });
  });
});

const blueprintsSlice = createSlice({
  name: blueprintsName,
  initialState,
  reducers: {
    resetGetStatus: (state) => {
      state.getStatus = GetStatus.IDLE;
      state.params = initialState.params;
      state.filteredBySearch = initialState.filteredBySearch;
    },
    resetAddStatus: (state) => {
      state.addStatus = AddStatus.IDLE;
      state.addedId = null;
    },
    resetUpdateStatus: (state) => {
      state.updateStatus = UpdateStatus.IDLE;
    },
    changeParams: (state, action) => {
      debugLog(action);
      state.params = action.payload;
      state.getStatus = GetStatus.CHANGED;
    },
    filteredBySearch: (state, action) => {
      state.filteredBySearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getBlueprints reducers
    builder.addCase(getBlueprints.pending, (state) => {
      state.getStatus = GetStatus.LOADING;
    });
    builder.addCase(getBlueprints.fulfilled, (state, action) => {
      state.getStatus = GetStatus.LOADED;
      state.items = action.payload.blueprints;
      state.pagination = action.payload.pagination;
      debugLog("GET BLUEPRINTS SUCCESS", action.payload);
    });
    builder.addCase(getBlueprints.rejected, (state, action) => {
      state.getStatus = GetStatus.ERROR;
      state.error = action.error.message;
      debugLog("GET BLUEPRINTS ERROR", action.error);
    });

    // addBlueprint reducers
    builder.addCase(addBlueprint.pending, (state) => {
      state.addStatus = AddStatus.ADDING;
    });
    builder.addCase(addBlueprint.fulfilled, (state, action) => {
      state.addStatus = AddStatus.ADDED;
      state.success = action.payload.message || "Blueprint added";
      state.getStatus = GetStatus.CHANGED;
      state.addedId = action.payload.blueprint.uuid;
      debugLog("ADDED", action.payload);
    });
    builder.addCase(addBlueprint.rejected, (state, action) => {
      state.addStatus = AddStatus.ERROR;
      state.error = action.error.message;
      debugLog("ADD ERROR", action.error);
    });

    // updateBlueprint reducers
    builder.addCase(updateBlueprint.pending, (state) => {
      state.updateStatus = UpdateStatus.UPDATING;
    });
    builder.addCase(updateBlueprint.rejected, (state, action) => {
      state.updateStatus = UpdateStatus.ERROR;
      state.error = action.error.message;
      debugLog("UPDATE ERROR", action.error);
    });

    builder.addCase(updateBlueprint.fulfilled, (state, action) => {
      state.updateStatus = UpdateStatus.UPDATED;
      state.success = "Blueprint updated";
      debugLog("UPDATED", action.payload);
      const updatedBlueprint: Blueprint = action.payload.blueprint;
      const existingBlueprint = state.items.find(
        (bp) => bp.uuid === updatedBlueprint.uuid
      );
      if (existingBlueprint) {
        existingBlueprint.name = updatedBlueprint.name;
      }
    });
  },
});

export const {
  changeParams,
  resetGetStatus,
  resetAddStatus,
  resetUpdateStatus,
  filteredBySearch,
} = blueprintsSlice.actions;

export const selectBlueprints: (s: RootState) => Blueprint[] = (state) =>
  state.blueprints.items;

export const selectTotalItems: (s: RootState) => number = (state) =>
  state.blueprints.pagination.total_items;

export const selectParams: (s: RootState) => GetParams = (state) =>
  state.blueprints.params;

export const selectBlueprintsLoading: (s: RootState) => boolean = (state) =>
  state.blueprints.getStatus === GetStatus.LOADING;

export const selectBlueprintsUpdating: (s: RootState) => boolean = (state) =>
  state.blueprints.updateStatus === UpdateStatus.UPDATING;

export default blueprintsSlice.reducer;
