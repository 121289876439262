import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducer";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "./rootReducer";
import { useDispatch } from "react-redux";

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Exporting like this means you can use then()/catch() on dispatch without
// Typescript freaking out
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
