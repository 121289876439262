import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";

export const contentLibraryName = "contentLibrary";

type ContentLibraryState = {
  isUpdating: boolean;
  filterTags: string[];
};
const initialState: ContentLibraryState = {
  isUpdating: false,
  filterTags: [],
};

const contentLibrarySlice = createSlice({
  name: contentLibraryName,
  initialState,
  reducers: {
    setIsUpdating: (state, action) => {
      state.isUpdating = action.payload;
    },
    setFilterTags: (state, action) => {
      state.filterTags = action.payload;
    },
  },
});

export const { setIsUpdating, setFilterTags } = contentLibrarySlice.actions;

export const selectTags: (s: RootState) => string[] = (state) =>
  state.contentLibrary.filterTags;

export const isUpdating: (s: RootState) => boolean = (state) =>
  state.contentLibrary.isUpdating;

export default contentLibrarySlice.reducer;
