import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import languageDetector from "i18next-browser-languagedetector";
import * as config from "./config.json";

const { NODE_ENV } = process.env;
const debug = NODE_ENV === "development";

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: config.fallbackLanguage,
    ns: [
      "translations",
      "CookieConsent",
      "ContentLibrary",
      "AskingTemplate",
      "AwarenessTemplate",
      "StewardshipReportingTemplate",
      "AskingTemplateExtended",
      "StewardshipReportingTemplateExtended",
      "SmallImageSmallArticlesTemplate",
      "BigVideoSmallArticlesTemplate",
      "SmallVideoSmallArticlesTemplate",
      "SmallVideoBigArticlesTemplate",
      "SmallVideoNoArticlesTemplate",
    ],
    debug,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
