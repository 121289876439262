import jwtManager from "../auth/session";
import debugLog from "../config/debugLog";

const statusCodesToIntercept = [401, 500];

const shouldIntercept = (error) => {
  // Don't intecept errors from the refresh URL
  let windowUrl = new URL(window.location);
  if (windowUrl.pathname === "/") {
    return false;
  }
  try {
    return statusCodesToIntercept.includes(error.response.status);
  } catch (e) {
    return false;
  }
};

const attachTokenToRequest = (request, token) => {
  request.headers["Authorization"] = "Bearer " + token;
};

const applyAppTokenRefreshInterceptor = (axiosClient, customOptions = {}) => {
  let isRefreshing = false;
  let failedQueue = [];

  const options = {
    attachTokenToRequest,
    shouldIntercept,
    ...customOptions,
  };
  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const interceptor = (error) => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error);
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }
    debugLog(error.config.url + " " + error.response.status);

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest._queued = true;
          options.attachTokenToRequest(originalRequest, token);
          return axiosClient.request(originalRequest);
        })
        .catch((err) => {
          debugLog(err);
          jwtManager.deleteJwt();
          Promise.reject(err); // FIXME: left this from a redirect function I removed. It doesn't seem to make any difference though. Consider removing.
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      jwtManager.getRefreshedToken().then((gotRefreshedToken) => {
        if (gotRefreshedToken) {
          const token = jwtManager.getJwt();
          processQueue(null, token);
          resolve(axiosClient.request(originalRequest));
        } else {
          processQueue(error, null);
          reject(error);
        }

        isRefreshing = false;
      });
    });
  };

  axiosClient.interceptors.response.use(undefined, interceptor);
};

export default applyAppTokenRefreshInterceptor;
