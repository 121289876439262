import { SystemUser } from "../types/SystemUser";
import { RootState } from "./rootReducer";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as config from "../config/config.json";
import axios from "axios";
import debugLog from "src/config/debugLog";
import { GetParams, Pagination } from "../types/Pagination";

const { REACT_APP_USERS_API_URL } = process.env;
const userApiUrl: string = REACT_APP_USERS_API_URL!;

export enum GetStatus {
  IDLE = "idle",
  LOADING = "loading",
  LOADED = "loaded",
  CHANGED = "changed",
  ERROR = "error",
}

interface GetUsersResponse {
  pagination: Pagination;
  users: SystemUser[];
}

type UserState = {
  items: GetUsersResponse["users"];
  pagination: GetUsersResponse["pagination"];
  params: GetParams;
  getStatus: GetStatus;
  error: string | undefined | null;
  success: string | undefined | null;
};

const initialState: UserState = {
  items: [],
  pagination: {
    current_page: 1,
    has_next: false,
    has_previous: false,
    next_page_number: null,
    number_of_pages: 1,
    previous_page_number: null,
    total_items: 0,
  },
  params: {
    page_size: config.users.defaultRowsPerPage,
    page: 1,
    q: null,
  },
  getStatus: GetStatus.IDLE,
  error: null,
  success: null,
};

export const getUsers = createAsyncThunk<
  // Return type of the payload creator
  GetUsersResponse,
  // First argument to the payload creator
  void,
  { state: RootState }
>("users/get", async (_, { getState, signal }) => {
  const timeout = config.apiCallTimeout;
  const { users } = getState();
  const params = users.params;

  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  debugLog("GET USERS");
  const response = await axios.get(`${userApiUrl}/`, {
    params: params,
    cancelToken: source.token,
    timeout: timeout,
  });
  return response.data as GetUsersResponse;
});

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetGetStatus: (state) => {
      state.getStatus = GetStatus.IDLE;
      state.params = initialState.params;
    },
    changeParams: (state, action) => {
      debugLog(action);
      state.params = action.payload;
      state.getStatus = GetStatus.CHANGED;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.getStatus = GetStatus.LOADING;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.getStatus = GetStatus.LOADED;
      state.items = action.payload.users;
      state.pagination = action.payload.pagination;
      debugLog("GET USERS SUCCESS", action.payload);
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.getStatus = GetStatus.ERROR;
      state.error = action.error.message;
      debugLog("GET USERS ERROR", action.error);
    });
  },
});

export const { resetGetStatus, changeParams } = usersSlice.actions;

export const selectUsers: (s: RootState) => SystemUser[] = (state) =>
  state.users.items;

export const selectParams: (s: RootState) => GetParams = (state) =>
  state.users.params;

export const selectUsersLoading: (s: RootState) => boolean = (state) =>
  state.users.getStatus === GetStatus.LOADING;

export default usersSlice.reducer;
