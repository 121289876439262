import axios from "axios";
import debugLog from "../config/debugLog";

const { REACT_APP_AUTH_API_URL_REFRESH } = process.env;

const jwtManager = () => {
  let myJwt: string | null = null;
  let isRefreshing: any = null;
  let refreshTimeOutId: number = -1;

  const getJwt = (): string | null => myJwt;

  const setJwt = (token: string, expiry: number): boolean => {
    myJwt = token;
    localStorage.setItem("jwt_expiry", `${expiry}`);
    // Only queue the refresh if the expiry is greater than zero and if it's not already been queued
    if (expiry > 0 && refreshTimeOutId === -1) {
      refreshToken(expiry);
    }
    return true;
  };

  const deleteJwt = (): boolean => {
    myJwt = null;
    isRefreshing = false;
    refreshTimeOutId = -1;
    abortRefreshToken();
    return true;
  };

  const refreshToken = (delay: number): void => {
    debugLog(`queueing refreshToken for ${delay}`);
    refreshTimeOutId = window.setTimeout(
      getRefreshedToken,
      delay * 1000 - 5000
    );
  };

  const abortRefreshToken = (): void => {
    if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
    }
  };

  const waitForTokenRefresh = () => {
    if (!isRefreshing) {
      return Promise.resolve(false);
    }
    return isRefreshing.then(() => {
      isRefreshing = null;
      return true;
    });
  };

  const getRefreshedToken = (): Promise<boolean> => {
    debugLog("CALLING refreshToken");
    const instance = axios.create({
      withCredentials: true,
    });
    const windowLocation = new URL(window.location.href);
    const domain = windowLocation.host;
    const post_data = {
      domain: domain,
    };
    isRefreshing = instance
      .post(REACT_APP_AUTH_API_URL_REFRESH!, post_data)
      .then((response): boolean => {
        let token = response.data.token;
        if (token) {
          let expiry = response.data.expiry;
          setJwt(token, expiry);
          return true;
        } else {
          deleteJwt();
          return false;
        }
      })
      .catch((err: string): boolean => {
        deleteJwt();
        return false;
      });
    return isRefreshing;
  };

  return {
    getJwt,
    setJwt,
    deleteJwt,
    getRefreshedToken,
    abortRefreshToken,
    waitForTokenRefresh,
  };
};

export default jwtManager();
